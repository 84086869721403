/* global gettext */
import { h, Fragment } from "preact"
import { useEffect, useState } from "preact/hooks"
import { Endpoint } from "../utils/endpoint"
import { CharField, TextField } from "./formFields"
import { RatingInterface } from "./rating"

export const Comments = ({ endpointUrl, article, ratingEndpointUrl }) => {
  const endpoint = new Endpoint(endpointUrl)

  return (
    <>
      <CommentsList
        endpoint={endpoint}
        article={article}
        ratingEndpointUrl={ratingEndpointUrl}
      />
      <CommentsForm endpoint={endpoint} article={article} />
    </>
  )
}

const CommentsList = ({ article, endpoint, ratingEndpointUrl }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      setData(await endpoint.getData(`article=${article}`))
    }
    fetchData()
  }, [])

  return (
    <>
      <h2 class="comments__title">{gettext("Comments")}</h2>
      <div class="comments__list">
        {data.length > 0 &&
          data.map((commentData) => (
            <Comment {...commentData} ratingEndpointUrl={ratingEndpointUrl} />
          ))}
      </div>
    </>
  )
}

const Comment = (data) => {
  return (
    <div class="card comments__comment">
      <div class="comment__meta">
        <div class="comment__meta__info">
          <div class="nickname">{data.nickname}</div>
          <div class="datetime">{data.created_at}</div>
        </div>
        <div class="rating comment__meta__rating">
          <RatingInterface
            endpointUrl={data.ratingEndpointUrl}
            data={{ type: "comment", id: data.id }}
          />
        </div>
      </div>
      {data.text && <div class="comment__text">{data.text}</div>}
    </div>
  )
}

const CommentsForm = ({ article, endpoint }) => {
  const [message, setMessage] = useState("")
  const [data, setData] = useState({})

  useEffect(() => {
    async function fetchData() {
      setData(await endpoint.getData(`form=${article}`))
    }
    fetchData()
  }, [])

  const setErrors = (errors) => {
    let newFieldsAndErrorData = { ...data }
    Object.keys(data).forEach((k) => {
      newFieldsAndErrorData[k]["errors"] = errors[k] || ""
    })
    setData(newFieldsAndErrorData)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)

    const response = await endpoint.postData(formData)
    if (response.valid) {
      setMessage(response.message)
    } else {
      setErrors(response.errors)
      setMessage("")
    }
  }

  return (
    <>
      {data && (
        <>
          {message === "" ? (
            <form class="card comments__form" onSubmit={onSubmit}>
              <h3 class="comments__form__title">{gettext("Your comment")}</h3>
              <input type="hidden" name="article" value={article} />
              <CharField name="nickname" {...data.nickname} />
              <TextField name="text" {...data.text} />
              <button class="button" type="submit">
                {gettext("Submit")}
              </button>
            </form>
          ) : (
            <div class="card comments__message">{message}</div>
          )}
        </>
      )}
    </>
  )
}
