/* globals gettext */
import { h, Fragment, render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { qsa } from "../utils/dom"
import { Endpoint } from "../utils/endpoint"
import { RadioChoicesField } from "./formFields"
import { useBreakpoint } from "../utils/breakpoints"

export const initCoaches = () => {
  qsa(".coaches-app").forEach((coachesApp) => {
    render(
      <CoachesList endpointUrl={coachesApp.dataset.endpoint} />,
      coachesApp,
    )
  })
}

export const initCoachTeaser = () => {
  qsa(".coach-teaser-app").forEach((coachTeaserApp) => {
    render(
      <CoachTeaser
        endpointUrl={coachTeaserApp.dataset.endpoint}
        coach={coachTeaserApp.dataset.coach}
      />,
      coachTeaserApp,
    )
  })
}

export const initCoachesTeaserList = () => {
  qsa(".coaches-teaser-app").forEach((coachTeaserApp) => {
    render(
      <CoachesTeaserList
        endpointUrl={coachTeaserApp.dataset.endpoint}
        topic={coachTeaserApp.dataset.topic}
        limit={coachTeaserApp.dataset.limit}
        moreLink={coachTeaserApp.dataset.moreLink}
      />,
      coachTeaserApp,
    )
  })
}

const CoachTeaser = ({ endpointUrl, coach }) => {
  const endpoint = new Endpoint(endpointUrl)
  const largeDisplay = useBreakpoint("md")
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams()
      if (coach) params.append("coach", coach)
      setData(await endpoint.getData(params || undefined))
    }
    fetchData()
  }, [])

  return (
    <a
      class={
        largeDisplay ? "coach__teaser coach__teaser--large" : "coach__teaser"
      }
      href={data.url}
    >
      {data ? <Coach {...data} /> : gettext("Coach not found.")}
    </a>
  )
}

const CoachesTeaserList = ({ endpointUrl, topic, limit, moreLink }) => {
  const endpoint = new Endpoint(endpointUrl)
  const largeDisplay = useBreakpoint("md")

  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams()
      // if (limit) params.append("limit", limit)
      if (topic) params.append("topic", topic)
      setData(await endpoint.getData(params || undefined))
    }
    fetchData()
  }, [])

  return (
    <div class="card default-list coaches__list">
      <div class="coaches__list__title">
        <h2>{gettext("Coaches")}</h2>
      </div>
      {data.length > 0
        ? data.sort(coachLanguageSort).map(
            (coach, i) =>
              (!limit || limit > i) && (
                <a
                  class={
                    largeDisplay
                      ? "card coach__teaser coach__teaser--large"
                      : "coach__teaser"
                  }
                  href={coach.url}
                >
                  <Coach {...coach} />
                </a>
              ),
          )
        : gettext("No coaches found.")}
      <div class="coaches__list__all">
        <a class="button" href={moreLink}>
          {gettext("show all coaches")}
        </a>
      </div>
    </div>
  )
}

const CoachesList = ({ endpointUrl }) => {
  const endpoint = new Endpoint(endpointUrl)
  const largeDisplay = useBreakpoint("md")

  const [data, setData] = useState([])
  const [topics, setTopics] = useState([])
  const [topicFilter, setTopicFilter] = useState("all")

  useEffect(() => {
    async function fetchData() {
      const newData = await endpoint.getData()
      setData(newData)
    }
    fetchData()
    async function fetchTopicsData() {
      setTopics(await endpoint.getData("topics=1"))
    }
    fetchTopicsData()
  }, [])

  const filteredData =
    data &&
    data
      .filter((coach) =>
        topicFilter !== "all"
          ? coach.topics.some((topic) => topic.id === topicFilter)
          : true,
      )
      .sort(coachLanguageSort)

  return (
    <>
      <div class="coaches__filter">
        {topics.length > 0 ? (
          <RadioChoicesField
            name="topics"
            classes="input-buttons"
            choices={topics}
            value={topicFilter}
            onInput={(e) => {
              setTopicFilter(e.target.value)
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div class="card default-list coaches__list coaches__list--with-filter">
        {filteredData.length > 0
          ? filteredData.map((coach) => (
              <a
                class={
                  largeDisplay
                    ? "card coach__teaser coach__teaser--large"
                    : "coach__teaser"
                }
                href={coach.url}
              >
                <Coach {...coach} />
              </a>
            ))
          : gettext("No coaches found.")}
      </div>
    </>
  )
}

const Coach = (data) => {
  const largeDisplay = useBreakpoint("md")

  return (
    <>
      {largeDisplay ? (
        <>
          <div class="coach__teaser__image">
            <img src={data.image} alt={data.name} />
          </div>
          <div class="coach__teaser__info">
            <div class="coach__teaser__tags">
              {data.languages?.map((language) => (
                <div class="tag coach__teaser__tag">{language.name}</div>
              ))}
            </div>
            <h3 class="coach__teaser__name">{data.name}</h3>
            <div class="coach__teaser__vita">{data.vita}</div>
            <div class="coach__teaser__more">
              <a class="more-link">
                <span>{gettext("to coach")}</span>
                <svg class="icon">
                  <use href="#icon-arrow" />
                </svg>
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="coach__teaser__tags">
            {data.languages?.map((language) => (
              <div class="tag coach__teaser__tag">{language}</div>
            ))}
          </div>
          <div class="coach__teaser__info">
            <div class="coach__teaser__image">
              <img src={data.image} alt={data.name} />
            </div>
            <h3 class="coach__teaser__name">{data.name}</h3>
          </div>
        </>
      )}
    </>
  )
}

const coachLanguageSort = (a, b) => {
  const aIncludes = a.languages?.some((l) => l.code == currentLanguage)
  const bIncludes = b.languages?.some((l) => l.code == currentLanguage)
  return aIncludes && bIncludes ? 0 : aIncludes && !bIncludes ? -1 : 1
}

const currentLanguage = document.documentElement.getAttribute("lang")
