/* global gettext */
import { h, render, Fragment } from "preact"
import { useState, useEffect } from "preact/hooks"
import { useBreakpoint } from "../utils/breakpoints"
import { qsa } from "../utils/dom"
import { Endpoint } from "../utils/endpoint"

export const initMeetings = () => {
  const meetingsApps = qsa(".meetings-app")
  meetingsApps.forEach((meetingsApp) => {
    render(
      <MeetingsList
        endpointUrl={meetingsApp.dataset.endpoint}
        topic={meetingsApp.dataset.topic}
        limit={meetingsApp.dataset.limit}
        moreLink={meetingsApp.dataset.moreLink}
      />,
      meetingsApp,
    )
  })
}

const MeetingsList = ({ endpointUrl, topic, limit, moreLink }) => {
  const endpoint = new Endpoint(endpointUrl)
  const [data, setData] = useState([])
  const largeDisplay = useBreakpoint("md")

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams()
      if (topic) params.append("topic", topic)
      // if (limit) params.append("limit", limit)
      setData(await endpoint.getData(params || undefined))
    }
    fetchData()
  }, [])

  return (
    <div class="card default-list meetings__list">
      {limit && (
        <div class="meetings__list__title">
          <h2>{gettext("Meetings")}</h2>
        </div>
      )}
      {data.length > 0
        ? data.sort(meetingsLanguageSort).map(
            (meeting, i) =>
              (!limit || limit > i) && (
                <div
                  class={
                    largeDisplay ? "card meeting meeting--large" : "meeting"
                  }
                >
                  <Meeting {...meeting} />
                </div>
              ),
          )
        : gettext("No meetings found.")}
      {moreLink && (
        <div class="meetings__list__all">
          <a class="button" href={moreLink}>
            {gettext("show all meetings")}
          </a>
        </div>
      )}
    </div>
  )
}

const Meeting = (data) => {
  return (
    <>
      <div class="meeting__meta">
        <div class="tags meeting__tags">
          {data.current ? (
            <div class="meeting__tag tag current">{gettext("now")}</div>
          ) : (
            ""
          )}
          {data.topics &&
            data.topics.map((topic) => (
              <div class="meeting__tag tag">{topic}</div>
            ))}
          {data.languages &&
            data.languages.map((lang) => (
              <div class="meeting__tag tag">{lang}</div>
            ))}
          <div class="meeting__tag tag">{data.type}</div>
        </div>
        <div class="meeting__participants">{data.participants}</div>
      </div>
      <div class="meeting__info">
        <h3 class="meeting__subject">{data.subject}</h3>
        <div class="meeting__date">{data.date}</div>
        {data.coach && (
          <div class="meeting__coach">
            {gettext("Moderated by")} {data.coach.join(", ")}
          </div>
        )}
        {data.address && <div class="meeting__address">{data.address}</div>}
        {data.description && (
          <div class="meeting__description">{data.description}</div>
        )}
        {data.registration ? (
          <div class="meeting__register">
            <a
              class="more-link"
              href={data.registration_link}
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>{gettext("register")}</span>
              <svg class="icon">
                <use href="#icon-arrow" />
              </svg>
            </a>
          </div>
        ) : data.link ? (
          <div class="meeting__register">
            <a
              class="more-link"
              href={data.link}
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>{gettext("participate")}</span>
              <svg class="icon">
                <use href="#icon-arrow" />
              </svg>
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}

const currentLanguage = document.documentElement.getAttribute("lang")

const meetingsLanguageSort = (a, b) => {
  const aCorrect = a.language == currentLanguage
  const bCorrect = b.language == currentLanguage
  return aCorrect && bCorrect ? 0 : aCorrect && !bCorrect ? -1 : 1
}
