const theme = {
  screens: { md: "45rem", lg: "64rem", xl: "80rem" },
}

module.exports = {
  customMedia: {
    "--media-md": `screen and (min-width: ${theme.screens.md})`,
    "--media-lg": `screen and (min-width: ${theme.screens.lg})`,
    "--media-xl": `screen and (min-width: ${theme.screens.xl})`,
  },
  theme,
}
