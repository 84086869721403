import { qs } from "../utils/dom"

export const initLeadAnimations = () => {
  const issueboxContainer = qs(".lead_0 .issuebox-teaser")

  if (issueboxContainer) {
    const setHeight = () => {
      document.body.style = `--lead-margin: ${issueboxContainer.offsetHeight}px`
    }

    setHeight()
    window.onresize = setHeight
  }
}
