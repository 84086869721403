import { h, render } from "preact"
import { qsa } from "../utils/dom"
import { Endpoint } from "../utils/endpoint"
import { useEffect, useState } from "preact/hooks"

export const initRatingInterface = () => {
  qsa(".rating-app").forEach((ratingApp) => {
    render(
      <RatingInterface
        endpointUrl={ratingApp.dataset.endpoint}
        data={{ type: ratingApp.dataset.type, id: ratingApp.dataset.id }}
      />,
      ratingApp,
    )
  })
}

export const RatingInterface = ({ endpointUrl, data }) => {
  const endpoint = new Endpoint(endpointUrl)
  const [count, setCount] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const [rated, setRated] = useState(false)

  const fetchData = async () => {
    const params = new URLSearchParams(data)
    let newData = await endpoint.getData(params)
    if (newData) {
      setCount(newData.count)
      setRated(newData.is_rated)
      setDisabled(false)
    }
  }

  const doRating = async () => {
    const params = new URLSearchParams(data)
    params.append("rate", 1)
    let newData = await endpoint.getData(params)
    if (newData.valid) fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <button
      class={`button button--rating${rated ? " rated" : ""}`}
      onClick={() => {
        setDisabled(true)
        doRating()
      }}
      disabled={disabled}
    >
      <span>{count}</span>
    </button>
  )
}
