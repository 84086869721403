import { qs } from "../utils/dom"
import getCookie from "../utils/get-cookie"

export const initCheckCookie = () => {
  const checkEl = qs("#check-cookie")
  if (checkEl) {
    const cookie = getCookie("sessionid")
    if (!cookie) {
      checkEl.style.display = "block"
    }
  }
}
