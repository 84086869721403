/* globals gettext */

import { h, Fragment } from "preact"
import { useEffect, useState } from "preact/hooks"
import { Endpoint } from "../utils/endpoint"
import {
  CharField,
  CheckboxChoicesField,
  CheckboxField,
  TextField,
} from "./formFields"

export const Issuebox = ({ endpointUrl }) => {
  const endpoint = new Endpoint(endpointUrl)

  const [step, setStep] = useState(0)
  const [data, setData] = useState(undefined)
  const [topics, setTopics] = useState([])
  const [handling, setHandling] = useState([])

  const setErrors = (errors) => {
    let nextStep = 2
    let newFieldsAndErrorData = { ...data }
    Object.keys(data).forEach((k) => {
      if (errors[k]) {
        newFieldsAndErrorData[k]["errors"] = errors[k]
        if (["nickname", "subject", "content"].includes(k))
          nextStep = 0 < nextStep ? 0 : nextStep
        if (["topics"].includes(k)) nextStep = 1 < nextStep ? 1 : nextStep
      }
    })
    setStep(nextStep)
    setData(newFieldsAndErrorData)
  }

  useEffect(() => {
    async function fetchData() {
      let newData = await endpoint.getData()
      setData(newData)
    }
    fetchData()
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const response = await endpoint.postData(formData)
    if (response.valid) {
      setStep(3)
    } else {
      setErrors(response.errors)
    }
  }

  return (
    <>
      {data && (
        <form
          id="issuebox__form"
          class={`container ce issuebox__form step-${step}`}
          onSubmit={onSubmit}
        >
          <Step key="0" current={step === 0}>
            <div class="step-display">
              {gettext("Step")} {step + 1} / 3
            </div>
            <h3>{gettext("Enter your request")}</h3>
            <CharField name="nickname" {...data.nickname} required={false} />
            <CharField name="subject" {...data.subject} required={false} />
            <TextField name="content" {...data.content} required={false} />
            <CheckboxField
              name="show_in_list"
              classes="custom-checkbox"
              {...data.show_in_list}
              required={false}
            />
            <div class="issuebox__control">
              <button
                type="button"
                class="button"
                onClick={() => {
                  setStep(1)
                }}
              >
                {gettext("next")}
              </button>
            </div>
          </Step>
          <Step key="1" current={step === 1}>
            <div class="step-display">
              {gettext("Step")} {step + 1} / 3
            </div>
            <h3>{gettext("Choose a topic")}</h3>
            <p>
              {gettext(
                "Which topic fits your request best? It is possible to select several.",
              )}
            </p>
            <CheckboxChoicesField
              name="topics"
              classes="input-buttons"
              {...data.topics}
              required={false}
              onInput={(value) => {
                let newTopics = [...topics]
                const valIndex = newTopics.indexOf(value)
                if (valIndex >= 0) newTopics.splice(valIndex, 1)
                else newTopics.push(value)
                setTopics(newTopics)
              }}
            />
            <div class="issuebox__control">
              <button
                type="button"
                class="button"
                onClick={() => {
                  setStep(0)
                }}
              >
                {gettext("back")}
              </button>
              <button
                type="button"
                class="button"
                onClick={() => {
                  setStep(2)
                }}
              >
                {topics.length === 0
                  ? gettext("None of the above")
                  : gettext("next")}
              </button>
            </div>
          </Step>
          <Step key="2" current={step === 2}>
            <div class="step-display">
              {gettext("Step")} {step + 1} / 3
            </div>
            <h3>{gettext("What next?")}</h3>
            <p>
              {gettext(
                "If you could choose one of the following options, what should you do next? You can choose more than one.",
              )}
            </p>
            <CheckboxChoicesField
              classes="input-buttons"
              name="handling"
              {...data.handling}
              onInput={(value) => {
                let newHandling = [...handling]
                const valIndex = newHandling.indexOf(value)
                if (valIndex >= 0) newHandling.splice(valIndex, 1)
                else newHandling.push(value)
                setHandling(newHandling)
              }}
              required={false}
            />
            {handling && handling.includes("private") && (
              <div class="issuebox__note">
                <div class="issuebox__note__head">
                  <svg class="icon">
                    <use href="#icon-note" />
                  </svg>
                  <span>{gettext("Note")}</span>
                </div>
                <div class="issuebox__note__text">
                  {gettext(
                    "You will receive your personal reply to the e-mail address with which you verified yourself.",
                  )}
                </div>
              </div>
            )}
            <div class="issuebox__control">
              <button
                type="button"
                class="button"
                onClick={() => {
                  setStep(1)
                }}
              >
                {gettext("back")}
              </button>
              <button type="submit" class="button">
                {gettext("send")}
              </button>
            </div>
          </Step>
          <Step key="3" current={step === 3}>
            <h3>{gettext("Thank you")}</h3>
            <p>
              {gettext(
                "Your request will be read by the operators of the platform in the next few days and, if you have requested it, it will be displayed in the protected area.",
              )}
            </p>
            <div class="mail--sent" />
          </Step>
        </form>
      )}
    </>
  )
}

const Step = (props) => {
  return (
    <div class={`card box-step ${props.current ? "current" : ""}`}>
      {props.children}
    </div>
  )
}
