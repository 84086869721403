/* globals gettext */
import { h, render, Fragment } from "preact"
import { useEffect, useState } from "preact/hooks"
import { qsa } from "../utils/dom"
import { Endpoint } from "../utils/endpoint"
import { RatingInterface } from "./rating"

export const initIssues = () => {
  qsa(".issues-app").forEach((issuesApp) => {
    render(
      <IssuesList
        endpointUrl={issuesApp.dataset.endpoint}
        ratingEndpointUrl={issuesApp.dataset.ratingEndpoint}
      />,
      issuesApp,
    )
  })
}

const IssuesList = ({ endpointUrl, ratingEndpointUrl }) => {
  const endpoint = new Endpoint(endpointUrl)
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      setData(await endpoint.getData())
    }
    fetchData()
  }, [])

  return (
    <div class="card default-list issues__list">
      {data.length > 0
        ? data.map((issue) => (
            <Issue {...issue} ratingEndpointUrl={ratingEndpointUrl} />
          ))
        : gettext("No issues found.")}
    </div>
  )
}

const Issue = (data) => {
  return (
    <div class="card issue">
      <div class="tags issue__tags">
        {data.topics.map((topic) => (
          <div class="tag issue__topic">{topic.title}</div>
        ))}
      </div>
      <div class="default-meta issue__meta">
        {data.nickname} — {data.date}
      </div>
      <h3 class="issue__subject">{data.subject}</h3>
      <div class="issue__content">{data.content}</div>
      <div class="issue__footer">
        <div class="rating issue__rating">
          <RatingInterface
            endpointUrl={data.ratingEndpointUrl}
            data={{ type: "issue", id: data.id }}
          />
        </div>
        <div class="issue__article">
          {data.article && (
            <a class="more-link" href={data.article}>
              <span>{gettext("Read matching article")}</span>
              <svg class="icon">
                <use href="#icon-arrow" />
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
