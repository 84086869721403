/* global gettext */
import { h, Fragment, render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { Endpoint } from "../utils/endpoint"
import { CharField, TextField } from "./formFields"
import { qsa } from "../utils/dom"

export const initContact = () => {
  const contactApps = qsa(".contact-app")
  contactApps.forEach((contactApp) => {
    render(
      <ContactForm
        endpointUrl={contactApp.dataset.endpoint}
        coach={contactApp.dataset.coach}
        mailBody={contactApp.dataset.mailBody}
        mailSubject={contactApp.dataset.mailSubject}
        useHeading={contactApp.dataset.useHeading}
      />,
      contactApp,
    )
  })
}

export const ContactForm = ({
  endpointUrl,
  coach,
  mailBody,
  mailSubject,
  useHeading,
}) => {
  const endpoint = new Endpoint(endpointUrl)

  const [message, setMessage] = useState("")
  const [data, setData] = useState({})

  useEffect(() => {
    async function fetchData() {
      setData(await endpoint.getData(`form=${coach}`))
    }
    fetchData()
  }, [])

  const setErrors = (errors) => {
    let newFieldsAndErrorData = { ...data }
    Object.keys(data).forEach((k) => {
      newFieldsAndErrorData[k]["errors"] = errors[k] || ""
    })
    setData(newFieldsAndErrorData)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)

    const response = await endpoint.postData(formData)
    if (response.valid) {
      setMessage(response.message)
    } else {
      setErrors(response.errors)
      setMessage("")
    }
  }

  return (
    <>
      {data && (
        <>
          {message === "" ? (
            <form class="card contact__form" onSubmit={onSubmit}>
              {useHeading && (
                <h2 class="contact__title">{gettext("get in touch")}</h2>
              )}
              <input type="hidden" name="coach" value={coach} />
              <CharField name="name" {...data.name} />
              <CharField name="phone" {...data.phone} />
              <CharField name="subject" {...data.subject} value={mailSubject} />
              <TextField name="text" {...data.text} value={mailBody} />
              <button class="button" type="submit">
                {gettext("Submit")}
              </button>
            </form>
          ) : (
            <div class="card contact__message">{message}</div>
          )}
        </>
      )}
    </>
  )
}
