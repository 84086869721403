import "./css/reset.css"
import "./css/colors.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/grid.css"
import "./css/buttons.css"
import "./css/layout.css"
import "./css/content.css"
import "./css/gallery.css"
import "./css/decorations.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/nav.css"
import "./css/issuebox.css"
import "./css/issues.css"
import "./css/articles.css"
import "./css/forms.css"
import "./css/icons.css"
import "./css/comments.css"
import "./css/lead.css"
import "./css/supporters.css"
import "./css/coaches.css"
import "./css/future-teaser.css"
import "./css/rating.css"
import "./css/messages.css"
import "./css/topics.css"
import "./css/meetings.css"
import "./css/lightboxes.css"
import "./css/cookies.css"

import "./css/admin.css"

import { onReady } from "./js/utils/dom"
import { initToggleNav } from "./js/components/nav"
import { initArticles, initArticleTeaser } from "./js/components/articles"
import { initIssues } from "./js/components/issues"
import { initIssuebox } from "./js/components/issueboxLoader"
import { initCheckCookie } from "./js/components/checkCookie"
import { initComments } from "./js/components/commentsLoader"
import {
  initCoaches,
  initCoachesTeaserList,
  initCoachTeaser,
} from "./js/components/coaches"
import { initRatingInterface } from "./js/components/rating"
import { initLightboxes } from "./js/components/lightboxes"
import { initMeetings } from "./js/components/meetings"
import { initContact } from "./js/components/contact"
import { initLeadAnimations } from "./js/components/lead"

onReady(initToggleNav)
onReady(initArticles)
onReady(initArticleTeaser)
onReady(initIssues)
onReady(initIssuebox)
onReady(initContact)
onReady(initCheckCookie)
onReady(initComments)
onReady(initMeetings)
onReady(initCoaches)
onReady(initCoachTeaser)
onReady(initCoachesTeaserList)
onReady(initRatingInterface)
onReady(initLightboxes)
onReady(initLeadAnimations)
