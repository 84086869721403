import getCookie from "./get-cookie"

/**
 * Handles Ajax requests on JSON endpoints
 */

export class Endpoint {
  constructor(url) {
    this.url = url
  }

  getData = async (query = "") => {
    let url = `${this.url}${query && "?"}${query}`
    let data = await fetch(url)
    if (data.redirected) {
      window.location.href = data.url
    }
    return data.json()
  }

  /**
   * @param {FormData} data
   */
  postData = async (data) => {
    let response = await fetch(this.url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: data,
    })

    return response.status > 201
      ? { message: `${response.status}: ${response.statusText}` }
      : response.json()
  }
}
