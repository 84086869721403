import { h, render } from "preact"
import { qsa } from "../utils/dom"
import { Comments } from "./comments"

export const initComments = () => {
  const commentApps = qsa(".comments-app")
  commentApps.forEach((commentApp) => {
    render(
      <Comments
        endpointUrl={commentApp.dataset.endpoint}
        ratingEndpointUrl={commentApp.dataset.ratingEndpoint}
        article={commentApp.dataset.article}
      />,
      commentApp,
    )
  })
}
