/* globals gettext */
import { h, Fragment, render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { qsa } from "../utils/dom"
import { Endpoint } from "../utils/endpoint"
import { RadioChoicesField, SearchField } from "./formFields"
import { RatingInterface } from "./rating"
import { useBreakpoint } from "../utils/breakpoints"

const paginateBy = 6

export const initArticles = () => {
  qsa(".articles-app").forEach((articlesApp) => {
    render(
      <ArticlesList
        endpointUrl={articlesApp.dataset.endpoint}
        ratingEndpointUrl={articlesApp.dataset.ratingEndpoint}
      />,
      articlesApp,
    )
  })
}

export const initArticleTeaser = () => {
  qsa(".article-teaser-app").forEach((articleTeaserApp) => {
    render(
      <ArticleTeaserList
        endpointUrl={articleTeaserApp.dataset.endpoint}
        ratingEndpointUrl={articleTeaserApp.dataset.ratingEndpoint}
        moreLink={articleTeaserApp.dataset.moreLink}
        limit={articleTeaserApp.dataset.limit}
        topic={articleTeaserApp.dataset.topic}
      />,
      articleTeaserApp,
    )
  })
}

const ArticleTeaserList = ({
  endpointUrl,
  ratingEndpointUrl,
  topic,
  limit,
  moreLink,
}) => {
  const endpoint = new Endpoint(endpointUrl)

  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams()
      if (limit) params.append("limit", limit)
      if (topic) params.append("topic", topic)
      setData(await endpoint.getData(params || undefined))
    }
    fetchData()
  }, [])

  return (
    <div class="card default-list articles__list">
      <div class="articles__list__title">
        <h2>{gettext("News articles")}</h2>
      </div>
      {data.length > 0
        ? data.map((article) => (
            <Article {...article} ratingEndpointUrl={ratingEndpointUrl} />
          ))
        : gettext("No articles found.")}
      <div class="articles__list__all">
        <a class="button" href={moreLink}>
          {gettext("show all articles")}
        </a>
      </div>
    </div>
  )
}

const unaccent = (s) => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
const formatSearch = (s) => unaccent(s).toLowerCase()

const ArticlesList = ({ endpointUrl, ratingEndpointUrl }) => {
  const endpoint = new Endpoint(endpointUrl)

  const [data, setData] = useState([])
  const [topics, setTopics] = useState([])
  const [topicFilter, setTopicFilter] = useState("all")
  const [searchFilter, setSearchFilter] = useState(undefined)
  const [slices, setSlices] = useState(1)

  useEffect(() => {
    async function fetchData() {
      const newData = await endpoint.getData()
      setData(
        newData.map((t) => ({
          ...t,
          searchString: formatSearch(t.title),
        })),
      )
    }
    fetchData()
    async function fetchTopicsData() {
      setTopics(await endpoint.getData("topics=1"))
    }
    fetchTopicsData()
  }, [])

  const filteredData =
    data &&
    data
      .filter((article) =>
        topicFilter !== "all"
          ? article.topics.some((topic) => topic.id === topicFilter)
          : true,
      )
      .filter((article) =>
        searchFilter
          ? article.searchString.includes(formatSearch(searchFilter))
          : true,
      )

  const shownData = filteredData.slice(0, slices * paginateBy)

  return (
    <>
      <div class="articles__filter">
        {topics.length > 0 ? (
          <RadioChoicesField
            name="topics"
            classes="input-buttons"
            choices={topics}
            value={topicFilter}
            onInput={(e) => {
              setTopicFilter(e.target.value)
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div class="articles__search">
        <SearchField
          name="search"
          label={gettext("Search")}
          onInput={(e) => {
            setSearchFilter(e.target.value)
          }}
        />
      </div>
      <div class="card default-list articles__list">
        {shownData.length > 0
          ? shownData.map((article) => (
              <Article {...article} ratingEndpointUrl={ratingEndpointUrl} />
            ))
          : gettext("No articles found.")}
        {filteredData.length > shownData.length && (
          <div class="articles__list__more">
            <button
              type="button"
              class="button"
              onClick={() => {
                setSlices(slices + 1)
              }}
            >
              {gettext("show more articles")}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

const Article = (data) => {
  const largeDisplay = useBreakpoint("md")

  return (
    <a
      class={largeDisplay ? "card article article--large" : "article"}
      href={data.url}
    >
      {!largeDisplay && (
        <div class="article__tags">
          {data.topics?.map((topic) => (
            <div class="tag article__tag">{topic.title}</div>
          ))}
        </div>
      )}
      <div class="article__image">
        <img
          src={largeDisplay ? data.lead_image : data.lead_image_square}
          alt={data.lead_image_alternative_text}
        />
        {largeDisplay && (
          <div class="article__tags">
            {data.topics?.map((topic) => (
              <div class="tag article__tag">{topic.title}</div>
            ))}
          </div>
        )}
      </div>
      <div class="article__info">
        <div class="default-meta article__meta">
          {data.author} — {data.date}
        </div>
        <div class="article__title">{data.title}</div>
        {largeDisplay && <div class="article__lead">{data.lead}</div>}
        <div class="article__footer">
          <div class="rating article__rating">
            <RatingInterface
              endpointUrl={data.ratingEndpointUrl}
              data={{ type: "article", id: data.id }}
            />
          </div>
          {largeDisplay && (
            <div class="article__more">
              <a class="more-link">
                <span>{gettext("learn more")}</span>
                <svg class="icon">
                  <use href="#icon-arrow" />
                </svg>
              </a>
            </div>
          )}
        </div>
      </div>
    </a>
  )
}
