/* globals gettext, interpolate */

import { h, Fragment } from "preact"
import { useState } from "preact/hooks"

export const CharField = (data) => {
  return (
    <fieldset class={data.classes}>
      <input
        type="text"
        name={data.name}
        required={data.required}
        value={data.value}
        readonly={data.readonly}
        placeholder={`${data.label}${data.required ? "*" : ""}`}
        onInput={data.onInput}
        maxlength={data.max_length}
      />
      <Label {...data} />
      <Error {...data} />
    </fieldset>
  )
}

export const SearchField = (data) => {
  const [value, setValue] = useState(data.value)

  return (
    <fieldset class={`searchfield ${data.classes ?? ""}`}>
      <input
        type="text"
        name={data.name}
        value={value}
        placeholder={`${data.label}`}
        onInput={(e) => {
          if (data.onInput) data.onInput(e)
          setValue(e.target.value)
        }}
      />
      <svg class="icon icon-search">
        <use href="#icon-search" />
      </svg>
      {value ? (
        <svg
          class="icon icon-delete"
          onClick={() => {
            if (data.onInput) data.onInput({ target: { value: "" } })
            setValue("")
          }}
        >
          <use href="#icon-close" />
        </svg>
      ) : (
        ""
      )}
    </fieldset>
  )
}

export const TextField = (data) => {
  return (
    <fieldset class={data.classes}>
      <textarea
        name={data.name}
        value={data.value}
        required={data.required}
        placeholder={`${data.label}${data.required ? "*" : ""}`}
        onInput={data.onInput}
        maxlength={data.max_length}
      />
      <Label {...data} />
      <Error {...data} />
    </fieldset>
  )
}

export const CheckboxField = (data) => {
  const [checked, setChecked] = useState(undefined)

  return (
    <fieldset class={data.classes}>
      <Error {...data} />
      <input
        id={data.name}
        type="checkbox"
        name={data.name}
        required={data.required}
        checked={checked}
        onInput={() => {
          setChecked(!checked)
        }}
      />
      <Label {...data}>
        {checked ? (
          <>
            <svg class="icon stroke">
              <use href="#icon-box" />
            </svg>
            <svg class="icon">
              <use href="#icon-check" />
            </svg>
          </>
        ) : (
          <svg class="icon stroke">
            <use href="#icon-box" />
          </svg>
        )}
      </Label>
    </fieldset>
  )
}

export const CheckboxChoice = (data) => {
  const [checked, setChecked] = useState(undefined)
  return (
    <>
      <input
        id={data.id}
        type="checkbox"
        name={data.name}
        key={data.id}
        value={data.c[0]}
        checked={checked}
        onInput={(e) => {
          if (data.onInput) data.onInput(e.target.value)
          setChecked(!checked)
        }}
      />
      <Label name={data.id} label={data.c[1]} classes="button--icon">
        {checked ? (
          <svg class="icon">
            <use href="#icon-check" />
          </svg>
        ) : (
          <svg class="icon">
            <use href="#icon-add" />
          </svg>
        )}
      </Label>
    </>
  )
}

export const CheckboxChoicesField = (data) => {
  return (
    <fieldset class={data.classes}>
      {data.choices
        .filter((c) => c[0])
        .map((c) => {
          const id = `${data.name}-${c[0]}`
          return <CheckboxChoice key={id} {...data} c={c} id={id} />
        })}
      {data.children}
      <Error {...data} />
    </fieldset>
  )
}

export const RadioChoicesField = (data) => {
  return (
    <fieldset class={data.classes}>
      {data.choices
        .filter((c) => c[0])
        .map((c) => {
          const id = `${data.name}-${c[0]}`
          return (
            <>
              <input
                id={id}
                type="radio"
                name={data.name}
                key={id}
                value={c[0]}
                onInput={data.onInput}
                checked={data.value && data.value === c[0]}
              />
              <Label name={id} label={c[1]} />
            </>
          )
        })}
      <Error {...data} />
    </fieldset>
  )
}

const Label = (data) => {
  return (
    <>
      <label for={data.name} class={data.classes || ""}>
        {data.children}
        <span>
          {data.label}
          {data.max_length
            ? ` ${interpolate(gettext("(max. %s characters)"), [
                data.max_length,
              ])}`
            : ""}
          {data.required && "*"}
        </span>
      </label>
    </>
  )
}

const Error = (data) => {
  return (
    <>
      {data.errors ? <div class="form-error">{data.errors.join(" ")}</div> : ""}
    </>
  )
}
